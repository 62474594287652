import * as cssText from "bundle-text:./tp-datepicker-native.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(cssText);

const template = document.createElement("template");
template.innerHTML = ` <div class="tp-date-picker">
          <span class="label"></span>
          <input type="date" />
      </div>`;

class TpDatePicker extends HTMLElement {
  static get observedAttributes() {
    return ["value", "disabled", "readonly", "label"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    this.$element = this.shadowRoot.querySelector("input");
    for (const attribute of this.attributes) {
      this.$element.setAttribute(attribute.name, attribute.value);
    }

    this.handleLabel();
    this.handleDisabled();
    this.handleReadOnly();
  }

  handleLabel() {
    const labelProperty = this.getAttribute("label");
    if (labelProperty !== null && labelProperty !== undefined) {
      this.shadowRoot.querySelector(".label").innerText = labelProperty;
    }
  }

  handleDisabled() {
    const disabledProperty = this.hasAttribute("disabled");

    if (disabledProperty) {
      this.shadowRoot
        .querySelector(".tp-date-picker")
        .classList.add("disabled");
    } else {
      this.shadowRoot
        .querySelector(".tp-date-picker")
        .classList.remove("disabled");
    }
  }

  handleReadOnly() {
    const readonlyProperty = this.hasAttribute("readonly");
    if (readonlyProperty) {
      this.shadowRoot
        .querySelector(".tp-date-picker")
        .classList.add("readonly");
    } else {
      this.shadowRoot
        .querySelector(".tp-date-picker")
        .classList.remove("readonly");
    }
  }

  handleValue() {
    const valueProperty = this.getAttribute("value");

    this.$input = this.shadowRoot.querySelector("input");
    if (valueProperty !== null && valueProperty !== undefined) {
      this.$input.setAttribute("value", valueProperty);
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;

    switch (name) {
      case "value":
        this.handleValue();
        break;

      case "disabled":
        this.handleDisabled();
        break;

      case "readonly":
        this.handleReadOnly();
        break;

      case "label":
        this.handleLabel();
        break;

      default:
        break;
    }
  }
}

customElements.define("tp-datepicker-native", TpDatePicker);
